import React from 'react';
import styles from './styles.module.scss'
import Client from '../Clients/Clients'

const About = ({refs}) => {

    return (
        <div className={styles.main} ref={refs}>
            
            <div className={styles.info}>
                <h1>À Propos</h1>
                <p>Vidéaste photographe passionné, je suis animé par une passion dévorante de la création de contenus qui racontent des histoires uniques. Mon parcours, forgé par des apprentissages, une exploration personnelle et une curiosité sans fin m’a permis d’évoluer dans mon domaine. Depuis maintenant 6 mois que FrameWork Pictures existe, j’ai pu réaliser et participer à de nombreux projets passionnants.
                </p>
            </div>

            <div className={styles.image_bg}>

                <div className={styles.image}>
                    <div className={styles.image_container}>
                        <img className={styles.img} src='/img/EVENT.jpg' alt='ilustration'></img>

                        <div className={styles.text}><h2>Événements</h2><p>Film after movie </p></div>
                    </div>
                    <div className={styles.image_container}>
                        <img className={styles.img} src='/img/REPORTAGE.jpg' alt='ilustration'></img>

                        <div className={styles.text}><h2>Reportages</h2><p>Documentaire, publicité</p></div>
                    </div>
                    <div className={styles.image_container}>
                        <img className={styles.img} src='/img/SOCIAL-MEDIA.jpg' alt='ilustration'></img>

                        <div className={styles.text}><h2>Réseaux sociaux</h2><p>Création de contenu..</p></div>
                    </div>
                    <div className={styles.image_container}>
                        <img className={styles.img} src='/img/FILM-CORPORATE.jpg' alt='ilustration'></img>

                        <div className={styles.text}><h2>Film Coroprate</h2><p>interview, shooting photo..</p></div>
                    </div>
                </div>

                <div className={styles.client}>

                    <Client />

                </div>

            </div>

        </div>
    )
};

export default About;